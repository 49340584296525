.main {
  position: relative;
  height: 100%;
}

:root {
  --base-shell-zoom: 0.71;
}

/* different starting zoom level for mobile  */
@media all and (max-width: 320px) and (orientation: portrait) {
  /* and (orientation: landscape) { */

  :root {
    --base-shell-zoom: 0.43;
  }
}

.shell {
  zoom: var(--base-shell-zoom);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.iShell {
  margin-top: 10px;
  align-items: center;
  display: inherit;
  background-color: rgb(100, 100, 100);
  overflow: scroll;

  /* height: fit-content; */
  border: 1px;
  border-color: whitesmoke;
  border-style: solid;
  height: 100%;
}

.IOshell {
}

.Oshell {
  overflow: scroll;
  position: relative;
  height: 100%;
  /* -webkit-overflow-scrolling: touch; */
}

.window-buttons {
  position: absolute;
  text-align: left;
  bottom: 0px;
  margin: 15px;
}
.window-buttons button {
  /* margin-right: 20px; */
  /* position: sticky; */
  width: 60px;
  height: 25px;
  /* font-size: 26px; */
}

.Master-Gain-Knob {
  display: flex;
  justify-content: center;
  position: relative;
  /* top: 30%; */
}

.Grey-Frame {
  background-color: rgb(158, 158, 158);
  margin: 21px;
}
