.App {
  /* min-height: -webkit-fill-available; */
  /* height: 100vh; */
  line-height: 1.5;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */

  touch-action: manipulation;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

html body {
  overscroll-behavior-x: contain;
  overflow-x: hidden;
}

#header,
#footer,
#content {
  position: absolute;
  left: 0;
  width: 100%;
}
#header,
#footer {
  /* overflow: hidden; */
  /* background: #444; */
}
#header {
  top: 0;
  height: 60px;
  /* overflow: hidden; */
}
#content {
  top: 61px;
  bottom: 60px;
  /* overflow: auto; */
  background: rgb(8, 8, 8);
}
#footer {
  bottom: 0;
  height: 60px;
  /* overflow-x: hidden; */
}

button:focus {
  outline: 0 !important;
}
