/* .footer button {
  margin: 20px 0px 0px 10px;
} */

.button-container {
  /* -ms-transform: translateY(50%); */
  /* transform: translateY(50%); */
  /* overflow: scroll; */
  /* width: 60px; */
}

.file-button {
  /* position: absolute;
  right: 0px; */
}

.footer {
  height: 65px;
  background-color: black;
  border-top: white solid 1px;
  /* bottom: 0px; */
  position: relative;
  display: flex;
  flex-direction: column;

  /* width: fit-content; */
  /* overflow: auto; */
}

.footer button {
  box-shadow: 0px 0px 0px 1px #9fb4f2;
  background: linear-gradient(to bottom, #7892c2 5%, #476e9e 100%);
  background-color: #7892c2;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  padding: 7px 7px;
  text-decoration: none;
  margin: 12px;
  font-weight: bold;
  min-width: 28px;
  /* border: 1px solid #4e6096; */
  /* text-shadow: 0px 1px 0px #283966; */
}
.footer button:hover {
  background: linear-gradient(to bottom, #476e9e 5%, #7892c2 100%);
  background-color: #476e9e;
}
.footer button:active {
  position: relative;
  top: 1px;
}
