h1 {
  padding: 0px;
  margin: 0px;
  color: darkslategrey;
  font-size: 40px;
  display: inline;
}

img {
  padding: 5px;
  margin: 0px;
  color: darkslategrey;
  font-size: 40px;
  /* display: inline; */
  width: 300px;
  height: 80%;
}

.Header {
  height: 100%;
  display: flex;
  -ms-flex-align: left;
  text-align: left;
  background-color: rgb(48, 2, 94);
  width: 100%;
}
