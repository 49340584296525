.Player {
  position: absolute;
  width: 100vw;
  height: 100vh;
  bottom: 0vh;
  pointer-events: none;
}

.Popup {
  background-color: whitesmoke;
  width: 300px;
  height: 350px;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  display: block;
  pointer-events: auto;
  border: silver;
  border-style: solid;
}

.Popup > * {
  /* padding: 0px; */
  margin: 0px;
}

#closePopup {
  color: red;
  position: absolute;
  right: 0%;
  margin: 10px;
}

.my-peak-meter {
  width: 100%;
  bottom: 0px;
  position: fixed;
}

.my-peak-meter > div {
  border: 0px solid #444;
  border-radius: 2px;
}

#closePopup,
#AddSlot {
  /*  padding: 0.3em 1.2em; */
  padding: 7px 10px;
  /* height: 30px; */
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #60a3bc;
  /* padding: 20px; */
  border-radius: 50px;
  display: inline-flex;
  border: none;
  transition: all 0.4s ease 0s;
  align-content: center;
}

.file-label {
  border: 1px slategrey;
  border-style: ridge;
}

#spinner:not([hidden]) {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#spinner::after {
  content: "";
  width: 80px;
  height: 80px;
  border: 2px solid #f3f3f3;
  border-top: 3px solid #f25a41;
  border-radius: 100%;
  will-change: transform;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
